<template>
    <Checkout></Checkout>
</template>

<script>
  import Checkout from "../../components/order-center/Checkout";
    export default {
        name: "ClinicCheckout",
      components: {Checkout}
    }
</script>

<style scoped>

</style>
